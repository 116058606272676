import { Box, useMediaQuery } from "@mui/material"
import { Theme } from "@mui/material/styles"
import { styled } from "@mui/system"
import { createPath, ROUTE } from "app-constants/routing"
import AccountLink from "components/account/AccountLink"
import { NavLinkProps } from "components/navigation/NavLink"
import TopNavigationContainer from "components/navigation/TopNavigationContainer"
import TopNavigationEmbedHorizontalMenu from "components/navigation/TopNavigationEmbedHorizontalMenu"
import TopNavigationHamburgerMenu from "components/navigation/TopNavigationHamburgerMenu"
import TopNavigationVerticalMenu from "components/navigation/TopNavigationVerticalMenu"
import { useExhibitorCopyText } from "hooks/exhibitors/useExhibitorsCopyText"
import { useUser } from "hooks/useUser"
import useCurrent from "lib/use-current"
import React, { useMemo } from "react"
import Headroom from "react-headroom"

const TopMacroEmbedNavigation = () => {
  const isWideScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"))

  const exhibitorsCopyText = useExhibitorCopyText()
  const current = useCurrent()
  const user = useUser()
  // @ts-ignore
  const currentConfig = current?.config

  const generalNavLinks: NavLinkProps[] = useMemo(
    () => [
      {
        href: createPath({ path: ROUTE.EMBED_CONVENTIONS }),
        text: currentConfig?.expo_copy_text ?? "Conventions",
      },
      {
        href: createPath({ path: ROUTE.EMBED_EXHIBITORS }),
        text: exhibitorsCopyText!.plural,
        isActive: (router) =>
          router.route === createPath({ path: ROUTE.EMBED_EXHIBITORS_BROWSE_ALL }),
      },

      ...(currentConfig?.people_index
        ? [
            {
              href: createPath({ path: ROUTE.EMBED_PEOPLE }),
              text: "People",
            },
          ]
        : []),

      {
        href: createPath({ path: ROUTE.EMBED_VIDEOS }),
        text: "Videos",
      },
    ],
    [currentConfig?.expo_copy_text, currentConfig?.people_index, exhibitorsCopyText],
  )

  if (!currentConfig?.expo_copy_text) return null
  if (!exhibitorsCopyText) return null

  return (
    /*@ts-ignore children type error occurs because of upgrading to React 18*/
    <Headroom>
      <TopNavigationContainer showDivider={false}>
        <_ContainerBox>
          {!isWideScreen && (
            <TopNavigationHamburgerMenu menuLabel="Menu" icon={"ListAlt"}>
              <TopNavigationVerticalMenu menuItems={generalNavLinks} />
            </TopNavigationHamburgerMenu>
          )}

          {isWideScreen && (
            <_MainNavigationContainer>
              <_TopNavigationEmbedHorizontalAuthMenu menuItems={generalNavLinks} />
            </_MainNavigationContainer>
          )}

          {user && <AccountLink />}
        </_ContainerBox>
      </TopNavigationContainer>
    </Headroom>
  )
}

export default TopMacroEmbedNavigation

const _ContainerBox = styled(Box)({
  display: "flex",
  width: "100%",
  height: "100%",
  flexFlow: "nowrap row",
  justifyContent: "space-between",
  alignItems: "center",
})

const _TopNavigationEmbedHorizontalAuthMenu = styled(TopNavigationEmbedHorizontalMenu)({
  marginLeft: "32px",
  ".MuiButton-root.MuiButton-root": {
    borderRadius: "10px",
  },
})

const _MainNavigationContainer = styled(_ContainerBox)({
  marginLeft: "0",
  width: "auto",
})
